<template>
  <!-- Footer Section -->
  <footer>
    <div class="container-fluid py-5">
      <div class="row d-flex justify-content-around">
        <div class="col-lg-8">
          <ul>
            <li class="my-2">
              <router-link to="/" :title="$t('Home')">
                {{ $t("Home") }}
              </router-link>
            </li>
            <li class="my-2">
              <span @click="scrollToSection('rooms')" :title="$t('Rooms')">
                {{ $t("Rooms") }}</span
              >
            </li>
            <li class="my-2">
              <router-link to="/Services" :title="$t('Services')">
                {{ $t("Services") }}
              </router-link>
            </li>
            <li class="my-2">
              <router-link to="/Gallery" :title="$t('Gallery')">{{
                $t("Gallery")
              }}</router-link>
            </li>
            <li class="my-2">
              <router-link to="/Location" :title="$t('Location')">{{
                $t("Location")
              }}</router-link>
            </li>
            <li class="my-2">
              <router-link to="/Contact" :title="$t('ContactUs')">
                {{ $t("ContactUs") }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <h3>{{ $t("AddressTitle") }}</h3>
          <p>
            {{ $t("Address") }}
          </p>
          <p>{{ $t("AntalyaTurkey") }}</p>
          <div class="social">
            <a
              href="https://www.instagram.com/siriustown.homes"
              class="mx-1"
              target="_blank"
              title="instagram"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="container d-flex justify-content-center my-5">
        <div class="row brands">
          <hr />
          <div class="col">
            <a href="https://antalyasuites.com" target="_blank" title="Antalya Suites">
              <figure>
                <img
                  src="../assets/logos/antalya-suites.png"
                  alt="Antalya Suites"
                  title="Antalya Suites"
                />
              </figure>
            </a>
          </div>
          <div class="col">
            <a href="https://geccele.com" target="_blank" title="Geccele">
              <figure>
                <img
                  src="../assets/logos/geccele.png"
                  alt="Geccele"
                  title="Geccele"
                  style="height: 40px"
                />
              </figure>
            </a>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer Section -->
</template>
<script>
export default {
  methods: {
    scrollToSection(sectionId) {
      let hostName = window.location.pathname;
      if (
        hostName == "/Services" ||
        hostName == "/Contact" ||
        hostName == "/Location" ||
        hostName == "/Gallery"
      ) {
        window.location = `/#${sectionId}`;
      } else {
        // İlgili bölümü bulmak için ID'ye göre seçim yapalım
        const section = document.getElementById(sectionId);

        if (section) {
          // Bölümü ekranın üstüne getirelim
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
  },
};
</script>
